import React, { createContext, Suspense, useEffect, useReducer } from "react";
import Immutable from "immutable";
import { matchPath, useHistory } from "react-router";
import { queryLoginState } from '../utils/resturl';
import request from '../utils/request'
import tpaths from '../jspathroute/index'
import { randomString } from "../utils/util";
import { initBasicConfigs } from "./configs";
import { initUserStockPrices } from "./userstockprices";
import { initPrintSaleConfig } from "./printConfigs";
import { initSaleDepartConfigs } from "../utils/getSaleDepart";
// const Login = React.lazy(() => import("../pages/loginbox/index"));
const tokenName = 'fcjx_login_token'
const tabsName = 'fcjx_lzy_tabs';
const Main = React.lazy(() => import("../main"));

let initialState = {
	loginning: 0,
	login: {},
	logined: 0,
	tabs: [],
	activeTab: '',
	eventType: '',
	eventValue: '',
	printTabs: [],
	changedTab: {
		action: {},
		changed: false,
	},//content变化的时候记录的信息
};
const loginReducer = (state, action) => {
	console.log("the action is::", action);
	if (action.type == "close_change_content_for_tab_pane") {
		let actions = state.changedTab?.action;
		let operType = action.payload.operType;
		state.changedTab = {
			changed: false,
			action: {}
		}
		if (operType == 0) {
			return Immutable.fromJS(state).toJS();
		}
		if (actions && operType == 1) {
			action = actions;
		}
	}
	switch (action.type) {
		case "refreshEvent":
			state.eventType = action.payload.eventType;
			state.eventValue = randomString(15);
			if (!!!action.payload.eventParam) {
				state.eventParam = null;
			} else {
				state.eventParam = action.payload.eventParam;
			}
			return Immutable.fromJS(state).toJS();
		case "loginning":
			state.loginning = 1;
			return Immutable.fromJS(state).toJS();
		case "login":
			state.logined = 1;
			state.login = action.payload.login;
			if (!!state.login && !!state.login.token) {
				localStorage.setItem(tokenName, state.login.token)
			}
			return Immutable.fromJS(state).toJS();
		case "logout":
			state.logined = 0;
			state.login = {};
			state.changeTabs = initialState.changeTabs;
			localStorage.removeItem(tokenName);
			localStorage.removeItem(tabsName);
			// eslint-disable-next-line no-fallthrough
			return Immutable.fromJS(state).toJS();
		case 'print_tab':
			{
				let tab1 = action.payload.tab;
				let tflg = false;
				(!!state.printTabs) && state.printTabs.forEach(it => {
					if (it.url === tab1.url) {
						tflg = true;
						if (!!action.payload && !!action.payload.params) {
							it.state = { ...action.payload.params }
						}
					}
				});
				if (!tflg) {
					let route = null;
					if (!!tpaths) {
						tpaths.forEach(it => {
							const result = matchPath(tab1.url, {
								path: it.path,
								exact: true,
							});

							if (!!result) {
								route = JSON.parse(JSON.stringify(it));
								route.match = {};
								route.match.params = JSON.parse(JSON.stringify(result.params))
								if (!!action.payload && !!action.payload.params) {
									route.state = { ...action.payload.params }
								}
							}
						})
					}
					if (!!route) {
						if (!!tab1.title)
							route.title = tab1.title;
						route.url = tab1.url;
						if (!state.printTabs) {
							state.printTabs = [];
						}
						state.printTabs.push({ ...route, active: true });
					}
				}
				// console.log('the login provider print tabs is::',state.printTabs)
				state.printTabs = [...(state.printTabs || [])]
				return Immutable.fromJS(state).toJS();
			}
		case 'print_end': {
			let url11 = action.payload.url;
			let tindex11 = -1;
			// let ttab=null;
			(!!state.printTabs) && state.printTabs.forEach((it, index) => {
				if (it.url === url11) {
					tindex11 = index;
					// ttab=it;
					return false;
				}
			})
			if (tindex11 == -1) {
				return Immutable.fromJS(state).toJS();
			}
			if (tindex11 >= 0) {
				state.printTabs.splice(tindex11, 1)
			}
			// console.log('准备关闭 print tabs is::',state.printTabs)
			state.printTabs = [...(state.printTabs || [])]
			return Immutable.fromJS(state).toJS();
		}
		case "add_new_tab":
			let tab = action.payload.tab;
			let tflg = false;
			// console.log('........add_new_tabadd_new_tabadd_new_tab...', state);
			(!!state.tabs) && state.tabs.forEach((it, index) => {
				if (it.url === tab.url) {
					tflg = true;
					it.active = true;
					if (tab.title)
						it.title = tab.title
					state.activeTab = tab.url;
					if (!!action.payload && !!action.payload.params) {
						it.state = { ...action.payload.params }
					}
				} else {
					it.active = false;
				}
			})
			if (!tflg) {
				let route = null;
				if (!!tpaths) {
					tpaths.forEach(it => {
						const result = matchPath(tab.url, {
							path: it.path,
							exact: true,
						});

						if (!!result) {
							route = JSON.parse(JSON.stringify(it));
							route.match = {};
							route.match.params = JSON.parse(JSON.stringify(result.params))
							if (!!action.payload && !!action.payload.params) {
								route.state = { ...action.payload.params }
							}
						}
					})
				}
				if (!!route) {
					if (!!tab.title)
						route.title = tab.title;
					route.url = tab.url;
					if (!state.tabs) {
						state.tabs = [];
					}
					state.tabs.push({ ...route, active: true });
					state.activeTab = tab.url;
				}
			}
			saveTabsSessionstorage(state.tabs, state.activeTab);
			return Immutable.fromJS(state).toJS();
		case "active_new_tab":
			{
				console.log('the login provider is::', 'active_new_tab',)
				let ttturl = action.payload.url;
				let ss = state.tabs.filter(ii => ii.url == ttturl);
				if (!!ss && ss.length > 0) {
					state.activeTab = ttturl;
				}
				console.log('the login provider is::', 'active_new_tab', ss, ttturl)
				return Immutable.fromJS(state).toJS();
			}
		case "change_content_for_tab_pane":
			{
				let ttturl = action.payload.url;
				let ss = state.tabs.find(iit => iit.url == ttturl);
				if (!ss) {
					return state;
				}
				if (!!ss && !!ss.changed) {
					return state;
				}
				ss.changed = true;
				return Immutable.fromJS(state).toJS();
			}
		case "cancel_change_content_for_tab_pane":
			{
				let ttturl = action.payload.url;
				let ss = state.tabs.find(iit => iit.url == ttturl);
				if (!ss) {
					return state;
				}
				if (!!ss && !ss.changed) {
					return state;
				}
				ss.changed = null;
				return Immutable.fromJS(state).toJS();
			}
		case "close_tab":
			let url = action.payload.url;
			let tindex = -1;
			// let ttab=null;
			(!!state.tabs) && state.tabs.forEach((it, index) => {
				if (it.url === url) {
					tindex = index;
					// ttab=it;
					return false;
				}
			})
			if (tindex == -1) {
				return Immutable.fromJS(state).toJS();
			}
			if (tindex >= 0) {
				let tTab = state.tabs[tindex];
				// let ttturl = action.payload.url;
				// let ss = state.tabs.find(iit => iit.url == ttturl);
				// console.log('.....111111111111...');
				if (!!tTab && !!tTab.changed && !!!action.payload.force) {
					action.payload.force = true
					state.changedTab = {
						changed: true,
						action: action
					}
					return Immutable.fromJS(state).toJS();
				} else {
					state.tabs.splice(tindex, 1)
					if (state.tabs.length > tindex) {
						state.tabs[tindex].active = true;
						state.activeTab = state.tabs[tindex].url;
					} else if (state.tabs.length > 0) {
						state.tabs[state.tabs.length - 1].active = true;
						state.activeTab = state.tabs[state.tabs.length - 1].url;
					} else {
						state.activeTab = '';
					}
					saveTabsSessionstorage(state.tabs, state.activeTab);
					return Immutable.fromJS(state).toJS();
				}
			}
		case "close_tab_m":
			if (!!state.tabs) {
				let tttabs = state.tabs.filter(it => !!matchPath(it.url, {
					path: action.payload.path,
					exact: true,
				}));
				state.tabs = tttabs;
			}
			saveTabsSessionstorage(state.tabs, state.activeTab);
			return Immutable.fromJS(state).toJS();
		case "init_tabs":   //当用户刷新页面的时候，需要从sessionstorage里面获取tabs，因此上面需要将tabs存入sessionstorage里面，
			state.tabs = action.payload.tabs;
			state.activeTab = action.payload.activeTab;
			return Immutable.fromJS(state).toJS();
		case "context_menu_close":
			let { paneKey, indx, closeType } = action.payload;
			//以下逻辑暂时未实现
			switch (closeType) {
				//关闭其他
				case "closeOthers": {
					let ss = state.tabs.filter(ii => ii.url == paneKey);
					let indx = state.tabs.findIndex(ii => ii.url != paneKey && ii.changed);
					if (indx != -1 && !!!action.payload.force) {
						action.payload.force = true
						state.changedTab = {
							changed: true,
							action: action
						}
						return Immutable.fromJS(state).toJS();
					}
					state.tabs = ss;
					state.activeTab = paneKey;
					saveTabsSessionstorage(state.tabs, state.activeTab);
					break;
				}
				//关闭左侧
				case "closeLefts": {
					let tindex = -1;
					state.tabs.forEach((iii, ttindex) => {
						if (iii.url == paneKey) {
							tindex = ttindex;
							return false;
						}
					})
					if (tindex == -1) {
						console.log('login provider close lefts is -1::');
						saveTabsSessionstorage(state.tabs, state.activeTab);
						return Immutable.fromJS(state).toJS();
					} else {
						let indx = state.tabs.findIndex((ii, inddx) => tindex > inddx && ii.changed);
						if (indx != -1 && !!!action.payload.force) {
							action.payload.force = true
							state.changedTab = {
								changed: true,
								action: action
							}
							return Immutable.fromJS(state).toJS();
						}
						let ss = state.tabs.slice(tindex);
						console.log('login provider close lefts is not -1::', tindex, ss)
						state.tabs = ss;
						state.activeTab = paneKey;
						saveTabsSessionstorage(state.tabs, state.activeTab);
					}
					break;
				}
				//关闭右侧
				case "closeRights": {
					let tindex = -1;
					state.tabs.forEach((iii, ttindex) => {
						if (iii.url == paneKey) {
							tindex = ttindex;
							return false;
						}
					})
					if ((tindex + 1) == state.tabs.length) {
						console.log('login provider close rights is  -1::', tindex)
						saveTabsSessionstorage(state.tabs, state.activeTab);
						return Immutable.fromJS(state).toJS();
					} else {
						let indx = state.tabs.findIndex((ii, inddx) => tindex <= inddx && ii.changed);
						if (indx != -1 && !!!action.payload.force) {
							action.payload.force = true
							state.changedTab = {
								changed: true,
								action: action
							}
							return Immutable.fromJS(state).toJS();
						}
						let ss = state.tabs.slice(0, tindex + 1);
						console.log('login provider close Rights is not -1::', tindex, ss)
						state.tabs = ss;
						state.activeTab = paneKey;
						saveTabsSessionstorage(state.tabs, state.activeTab);
					}
					break;
				}
				//关闭全部
				case "closeAll": {
					console.log('login provider close closeAll is  ::',)
					let indx = state.tabs.findIndex(ii => ii.changed);
					if (indx != -1 && !!!action.payload.force) {
						action.payload.force = true
						state.changedTab = {
							changed: true,
							action: action
						}
						return Immutable.fromJS(state).toJS();
					}
					state.tabs = [];
					state.activeTab = '';
					saveTabsSessionstorage(state.tabs, state.activeTab);
					break;
				}
				default:
					break;
			}
			return Immutable.fromJS(state).toJS();
		default:
			console.log("login reducer defuat");
			return Immutable.fromJS(state).toJS();
	}
};
const LoginContext = createContext();

const saveTabsSessionstorage = async (tabs, activeTab) => {
	// console.log('saveTabsSessionstorage:::',tabs,activeTab)
	if (tabs == null || tabs.length <= 0) {
		await localStorage.removeItem(tabsName);
		return;
	}
	let tobj = { tabs: tabs, activeTab: activeTab };
	await localStorage.setItem(tabsName, JSON.stringify(tobj));
}

const readTabsSessionstorage = async () => {
	let tstr = await localStorage.getItem(tabsName);
	if (!!tstr) {
		let tobj = JSON.parse(tstr);
		// console.log('readTabsSessionstorage:::tstr',tstr,tobj)
		return tobj;
	}
	return null;
}
///wfcjx/resource
const LoginProvider = (props) => {
	const [loginState, loginDispatch] = useReducer(loginReducer, initialState);
	const history = useHistory();
	useEffect(() => {
		const pathname = !!history && !!history.location ? history.location.pathname : ''
		let login_token = localStorage.getItem(tokenName);
		if (!!login_token) {
			request(queryLoginState, { body: { loginToken: login_token } }).then(async res => {
				if (res.code == 0) {
					if (!!res.item.companyId) {
						await initBasicConfigs();
						await initUserStockPrices();
						await initPrintSaleConfig();
						await initSaleDepartConfigs();
					}
					loginDispatch({ type: 'login', payload: { login: res.item } });
					if (pathname == '/work/potal/SignIn' || pathname == '/work/potal/forgetPassword' || pathname == '/work/potal/register') {
						history.push('/work/merchant/main');
					}
				} else {
					if (!!pathname && pathname.indexOf('/work/merchant/main') > -1) {
						history.push('/work/potal/SignIn');
					}
				}
				let tobj = await readTabsSessionstorage();
				if (!!tobj) {
					// console.log('readTabsSessionstorage:::in login ',tobj)
					loginDispatch({ type: 'init_tabs', payload: { tabs: tobj.tabs, activeTab: tobj.activeTab } });
				}
			});

		} else {
			if (pathname.indexOf('/work/merchant/main') > -1) {
				history.push('/work/potal/SignIn');
			}
		}
	}, [history]);

	return (
		<LoginContext.Provider value={{ state: loginState, dispatch: loginDispatch }}>
			<Suspense fallback={<div>正在加载中......</div>}>
				{props.children}
				<Main />
				{/* {loginState.logined === 0 ? <Login /> : <Main />}  */}
			</Suspense>
		</LoginContext.Provider>
	);
};
export { LoginProvider, LoginContext };
