import React, { useContext, useEffect, useRef, useState } from 'react';
import { ConfigProvider, Radio } from 'antd';
import logo from './logo.svg';
import "antd/dist/antd.css";
import { LoginProvider } from './providers/loginProvider'
import zhCN from 'antd/lib/locale/zh_CN';
import "./App.css";


const App = (props) => {
  //发现有一些组件为英文，这里对国际化语言进行设置，默认为中文，
  const [locale, setLocale] = useState(zhCN)
  return (
    <div className="App">
      <LoginProvider key={locale ? locale.locale : 'en'} />
    </div>
  );
}

export default App;
