import request from "../utils/awaitrequest";
/**
 * {
 *     stockViewPrice://出入库是否显示价格0:1
 *     saleViewStock://业务员能否看库存,0:1
 *     saleViewStockType:///业务员查看库存的类型，可用库存1，实时库存2     
 *     batchEnable:0,1,
 *     productionTime:0,1
 * }
 */
let userStockPrices = [];
const compaynConfUrl = '/role/stock/price/type/user/list'
export function getUserStockPrices() {
    return [...(userStockPrices||[])]
}

export async function initUserStockPrices(){
    await request(compaynConfUrl).then((res) => {
        if (res.code == 0) {
            userStockPrices = res.item;
        }
    })    
}