import { message } from "antd";

export const randomString = (length) => {
    var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
        result += str[Math.floor(Math.random() * str.length)];
    return result;
};
//判断文件格式是不是图片
export const isAssetTypeAnImage = (ext) => {
    if (!!!ext) {
        return false;
    }
    let ff = ['.png', '.jpg', '.jpeg', '.bmp', '.gif', '.webp', '.psd', '.svg', '.tiff']
    let i = -1;
    ff.forEach(element => {
        if (ext.indexOf(element) != -1)
            return i = 1;
    });
    return i > -1
}

export const get_base64_size = (base64, dwidth, dheight) => {
    if (base64 == null) {
        return {
            width: dwidth,
            height: dheight,
        }
    }
    //确认处理的是png格式的数据
    if (base64.substring(0, 22) === 'data:image/png;base64,') {
        // base64 是用四个字符来表示3个字节
        // 我们只需要截取base64前32个字符(不计开头那22个字符)便可（24 / 3 * 4）
        // 这里的data包含12个字符，9个字节，除去第1个字节，后面8个字节就是我们想要的宽度和高度
        const data = base64.substring(22 + 20, 22 + 32);
        const base64Characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
        const nums = [];
        for (const c of data) {
            nums.push(base64Characters.indexOf(c));
        }
        const bytes = [];
        for (let i = 0; i < nums.length; i += 4) {
            bytes.push((nums[i] << 2) + (nums[i + 1] >> 4));
            bytes.push(((nums[i + 1] & 15) << 4) + (nums[i + 2] >> 2));
            bytes.push(((nums[i + 2] & 3) << 6) + nums[i + 3]);
        }
        const width = (bytes[1] << 24) + (bytes[2] << 16) + (bytes[3] << 8) + bytes[4];
        const height = (bytes[5] << 24) + (bytes[6] << 16) + (bytes[7] << 8) + bytes[8];
        if (!!dheight && dheight > 0) {
            let twidth = parseInt(parseFloat(dheight) * parseFloat(width) / parseFloat(height));
            console.log('计算过程中....', twidth)
            return {
                width: twidth,
                height: dheight,
            }
        } else if (!!dwidth && dwidth > 0) {
            let theight = parseInt(parseFloat(dwidth) * parseFloat(height) / parseFloat(width));
            return {
                width: dwidth,
                height: theight,
            }
        }
        return {
            width: !!dwidth ? dwidth : 70,
            height: !!dheight ? dheight : 70,
        };
    }
    return {
        width: dwidth,
        height: dheight
    }
}

//销售产品说明
export const getPromotionNote = (record, brackets) => {
    let pret = ''
    if (record.gift == 1 && record.giftFrom == 0) {
        pret = brackets ? '活动赠品' : '[活动赠品]';
        return pret;
    }
    if ((record.gift == 1 && record.giftFrom == 1)) {
        pret = brackets ? '手动赠品' : '[手动赠品]';
        return pret;
    }
    if (!!record.promotionId) {
        switch (record.promotionType) {
            case 1:
                {
                    pret = brackets ? '满减' : '[满减]';
                    break;
                }
            case 2:
                {
                    pret = brackets ? '满赠' : '[满赠]';
                    break;
                }
            case 3:
                {
                    pret = brackets ? '特价' : '[特价]';
                    break;
                }
            case 4:
                {
                    if (record.orientType == 1) {
                        pret = brackets ? '预存款' : '[预存款]';
                    } else if (record.orientType == 2) {
                        pret = brackets ? '预存货' : '[预存货]';
                    }
                    break;
                }

        }
    }
    return pret;
}
//采购产品说明
export const getOrientedNote = (record, showOrientNum = true, brackets) => {
    if (!!!record) {
        return ''
    }
    let pret = ''
    if (record.gift == 1) {
        pret = brackets ? '赠品' : '[赠品]';
        return pret
    }
    if (!!record.orientId) {
        switch (record.orientType) {
            case 1:
                {
                    pret = brackets ? '预存款' : '[预存款]';
                    break;
                }
            case 2:
                {
                    pret = showOrientNum ? (brackets
                        ? ('预存货:' + (record.remainNumber || 0))
                        : ('[预存货:' + (record.remainNumber || 0) + ']'))
                        : (brackets ? '预存货' : '[预存货]');
                    break;
                }
        }
    }
    return pret;
}
//多列排序
export const getSortList = (sorter) => {
    let tt = [];
    if (!Array.isArray(sorter)) {
        if (!!sorter.order) {
            tt = [{
                column: sorter.columnKey,
                sort: sorter.order == 'ascend' ? 0 : sorter.order == 'descend' ? 1 : -1,
            }]
        }
    } else {
        (sorter || []).forEach(it =>
            tt.push(
                {
                    column: it.columnKey,
                    sort: it.order == 'ascend' ? 0 : it.order == 'descend' ? 1 : -1,
                }
            )
        );
    }
    tt = tt.filter(tit => tit.sort >= 0)
    return tt
}


//获取商品的key带date
export const getGoodsKeyWithDate = (record) => {
    record = record || {}
    return record.goodsId + '-' + (record.promotionId || 'nopromotion') + '-' + (record.orientId || 'noorient') + '-' + (record.produceDate || 'nodate') + '-' + (record.gift || 'nogift');
}

//获取商品的key
export const getGoodsKey = (record) => {
    record = record || {}
    return record.goodsId + '-' + (record.promotionId || 'nopromotion') + '-' + (record.orientId || 'noorient')
}

export const questionTreeList = (list) => {
    let treeData = [];
    list.forEach(element => {
        // element.key = element.id
        if (!element.pid || element.pid == 0) {
            treeData.push(element);
        } else {
            list.forEach(ele1 => {
                if (element.pid === ele1.id) {
                    if (ele1.children == null) {
                        ele1.children = [];
                    }
                    ele1.children.push(element);
                }
            })
        }
    })
    return treeData;
}
export const questionSelectTree = (list) => {

    let treeData = [];
    let hasName = {};
    list.forEach(element => {
        element.title = element.name
        element.value = element.id
        element.key = element.id
        if (!element.pid || element.pid == 0) {
            treeData.push(element);
            hasName[element.id] = element.name
            hasName[element.name] = element.id
        } else {
            list.forEach(ele1 => {
                if (element.pid === ele1.id) {
                    if (hasName[element.name]) {
                        hasName[element.id] = hasName[element.pid] + '-' + element.name;
                        hasName[element.name] = element.id;
                    } else {
                        hasName[element.id] = element.name
                        hasName[element.name] = element.id
                    }
                    if (ele1.children == null) {
                        ele1.children = [];
                    }
                    element.name = hasName[element.id]
                    element.title = hasName[element.id]
                    ele1.children.push(element);
                }
            })
        }
    })
    return treeData;
}

export const updateTreeData = (tree, id, children) => {
    if (!tree || tree.length <= 0) {
        return [...children];
    }
    return tree.map(node => {
        if (node.id == id) {
            return {
                ...node,
                children
            };
        }
        if (!!node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, id, children)
            }
        }
        return { ...node };
    })
}

//生产日期字符串正则表达式校验
export const datePattern = new RegExp(
    /^(\d{4})((-||—){0,1})(0\d{1}|1[0-2])((-||—){0,1})(0\d{1}|[12]\d{1}|3[01])$/
);

export const monthPattern = new RegExp(
    /^(\d{4})((-||—){0,1})(0\d{1}|1[0-2])((-||—){0,1})$/
);

//处理生产日期
export const getProduceDate = (value, errMsg, format) => {
    let lng = (format || 'yyyy-MM-dd').length
    value = value || '';
    value = ((value || '').length > lng ? (value || '').slice(0, lng) : (value || ''));
    let g = value.replace(new RegExp(/\D/g), "");
    if (value && g.length >= (lng == 10 ? lng - 2 : lng - 1)) {
        if ((!datePattern.test(g) && lng == 10) || (!monthPattern.test(g) && lng == 7)) {
            message.fail(
                errMsg || ("生产日期格式错误！" +
                    "请输入" +
                    "20220101或者2022-01-01" +
                    "格式" +
                    "!")
                , 2);
            value = "";
        } else {
            value = g.slice(0, 4) + '-' + g.slice(4, 6) + '-' + g.slice(6)
            value = value.slice(0, lng)
        }
    }
    return value

}

// tofixed会四舍五入  第三个参数就是不四舍五入按照截取字符串的方式转成number
export const toFixed = (num, lgth = 2, toStr = false) => {
    if(num==null){
        return 0;
    }
    if (isNaN(num))
        return 0;
    if (!!!num || num === '') {
        return 0;
    }
    if (num.toString().split('.')[1]) {
        if ((num.toString().split('.')[1]).length > lgth) {
            if (toStr) {
                return Number((num + '').slice(0, (num + '').indexOf('.') + (lgth + 1)))
            }
            return Number(Number(num + '').toFixed(lgth))
        } else {
            return Number(num + '')
        }
    } else {
        return Number(num);
    }
}

// 数值是否相等 相等返回true 否则返回false
export const equal = (a, b, acc = 0.01) => {
    let data1 = a || 0;
    let data2 = b || 0;
    if (isNaN(data1) || isNaN(data2)) {
        return false
    } else {
        if (Math.abs(parseFloat(data1 || 0) - parseFloat(data2 || 0)) < parseFloat(acc || 0)) {
            return true
        } else {
            return false
        }
    }
}

// 判断两个浮点数大小,并且相差小于精度值 
//a>b 返回1   a<b 返回0  a = b 返回2  不是数字返回 -1  
export const compare = (a, b, acc = 0.01) => {
    let data1 = a || 0;
    let data2 = b || 0;
    if (isNaN(data1) || isNaN(data2)) {
        return -1;
    } else {
        if (!!equal(data1, data2, acc)) {
            return 2;
        } else {
            if (parseFloat(data1 || 0) > parseFloat(data2 || 0)) {
                return 1;
            } else {
                return 0;
            }
        }
    }
}
//数组去除重复
export const dupRemArr = (arr, key) => {
    let ff = [];
    if (arr) {
        arr.map(it => {
            if (typeof it == "string" || typeof it == "number") {
                if (ff.findIndex(itt => itt == it) == -1) {
                    ff.push(it);
                }
            } else {
                if (it[key] && (ff.findIndex(itt => itt[key] == it[key]) == -1)) {
                    ff.push(it);
                }
            }
        })
        return ff;
    }
    return [];
}

export const parseNumberFromStr = (numberStr) => {
    if (!numberStr || numberStr == '') {
        return [];
    }
    numberStr = numberStr + ''
    let num = /\d+(.\d+)?/g;
    let numbers = numberStr.match(num);
    if (!numbers) {
        return []
    }
    return numbers.map(it => parseFloat(it));
}

// //去除首尾空格
export const trimSpace = (str) => {
    if (str == 0) {
        return str + ''
    }
    if (str) {
        str = str + ''
        return str.replace(/^\s+|\s+$/g, "")
    }
    return null;
}
export const uploadMaxSize = 5//上传文件最大限制值

export const imgHost = '/serverimg'
export const downloadHost = '/serverdownload/'
//android和ios下载地址
export const androidDownLoadAddr = 'https://laozhuyi.cn/' + downloadHost + '/%E8%80%81%E4%B8%BB%E6%84%8F.apk';
export const iosDownLoadAddr = '';

export const api = '/api'
export const numberRequestedVersion = '1.1.5';//最低版本号
// export const imgHost = 'http://192.168.1.82:9000'
// export const imgHost = 'http://39.105.22.214:9000'
// export const downloadHost = 'http://192.168.1.66:3000/serverdownload/'