import { getAntiRepeat, getClientHeader, saveAntiRepeat } from './clientToken'
import { api, numberRequestedVersion } from './util';
let proxy = api;
export const TypeEnum = 'no';


function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        let headers = response.headers;
        if (!!headers) {
            let antObj = getAntiRepeat();
            if (headers.get('antiRepeat') && headers.get('antiRepeat') != antObj.antiRepeatToken && headers.get('antiRepeat') != antObj.lastAntiRepeatToken) {
                console.log('...checkStatus..headers.....', headers.get('antiRepeat'), '........headers>>>>>>>>')
                saveAntiRepeat({ antiRepeatToken: headers.get('antiRepeat') })
            }
        }
        return response.json();
        // let res=response.json();
        // if(res.code==2){
        //     window.location.href="/work/potal/SignIn";
        // }
        // return res;
    } else {
        return new Promise((resolve) => resolve({ msg: '请求出错，网络状态码：' + response.status, code: 10, }));
        // return { msg: '没有正常返回', code: 10 ,item:{}};
    }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default async function request(url, options) {
    const defaultOptions = {
        credentials: 'include',
    };

    let newOptions = { ...defaultOptions, ...(options || {}), method: (options || {}).method || 'POST' };
    if (newOptions.method === 'POST' || newOptions.method === 'PUT') {
        let tt = getClientHeader();
        newOptions.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            ...newOptions.headers,
            "laozhuyi-type": tt.type,
            "laozhuyi-token": tt.token,
            "laozhuyi-sign": tt.sign,
            "lzy_client_version": numberRequestedVersion,
        };
        // console.log('...request..antiRepeat.....', antiRepeatToken, '........utl>>>>>>>>', url)
        let antObj = getAntiRepeat();
        if (!!antObj.antiRepeatToken) {
            newOptions.headers.antiRepeat = antObj.antiRepeatToken;
            saveAntiRepeat({ lastAntiRepeatToken: antObj.antiRepeatToken })
        }

        newOptions.body = JSON.stringify(newOptions.body)
    }

    const path = proxy + url;
    return await fetch(path, newOptions)
        .then(checkStatus)
        .then(json => {
            if (json.code == 2) {
                window.location.href = '/work/potal/SignIn';
                // throw new Error('需要登录');
            }
            return new Promise((resolve) => resolve(json));
        }).catch((error) => {
            console.log("ajax is error!", error);
            return { msg: '接口：' + url + '调用出错,错误原因：' + error, code: 10, };
        });
}