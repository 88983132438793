import request from "../utils/awaitrequest";
/**
 * {
 *     stockViewPrice://出入库是否显示价格0:1
 *     saleViewStock://业务员能否看库存,0:1
 *     saleViewStockType:///业务员查看库存的类型，可用库存1，实时库存2     
 *     batchEnable:0,1,
 *     productionTime:0,1
 * }
 */
let configs = {};
const compaynConfUrl = '/common/company/conf/one'
export function busiConfigs() {
    if (!!!configs) {
       return null;
    }
    return { ...configs };
}

export async function initBasicConfigs(){
    await request(compaynConfUrl).then((res) => {
        if (res.code == 0) {
            console.log('inner initBasicConfigs......',res.item)
            configs = res.item;
        }
    })    
}