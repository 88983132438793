export default [
  {
    path: "/pages/logistics/logInformation",
    title: "物流公司列表",
  },
  {
    path: "/pages/logistics/delivery",
    title: "配送管理",
  },
  {
    path: "/pages/logistics/add/:id/delivery",
    title: "新增配送管理",
  },
  {
    path: "/pages/logistics/watchDeliveryMap",
    title: "查看送货地图",
  },
  {
    path: "/pages/logistics/logList",
    title: "我的发货",
  },
  {
    path: "/pages/logistics/myReceipt",
    title: "我的收货",
  },
  {
    path: "/pages/logistics/inspection",
    title: "验货",
  },
  {
    path: "/pages/logistics/:id/logisticsDetails",
    title: "物流单列表详情",
  },
  {
    path: "/pages/logistics/receiving",
    title: "收货地址管理",
  },
  {
    path: "/pages/businessDistrict/distributor/distributor",
    title: "找经销商",
  },
  {
    path: "/pages/potal/SignIn",
    title: "登录",
  },
  {
    path: "/pages/setUp/softwareVersion",
    title: "应用版本信息",
  },
  {
    path: "/pages/setUp/userAgreement",
    title: "用户协议",
  },
  {
    path: "/pages/setUp/serviceIntroduce",
    title: "服务介绍",
  },
  {
    path: "/pages/setUp/privacy_policy",
    title: "隐私政策",
  },
  {
    path: "/pages/setUp/displaySetting",
    title: "陈列费用模板",
  },
  {
    path: "/pages/setUp/codingRules",
    title: "单据编码规则",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/logView",
    title: "日志查看",
  },
  {
    path: "/pages/setUp/dataSharing",
    title: "数据分享设置",
  },
  {
    path: "/pages/setUp/salesConfiguration",
    title: "销售设置",
  },
  {
    path: "/pages/setUp/regionalSetting",
    title: "按区域设置",
  },
  {
    path: "/pages/setUp/configure",
    title: "进销存设置",
  },
  {
    path: "/pages/setUp/datemanagement",
    title: "设置产品日期管理",
  },
  //已删除
  // {
  //     path: '/pages/setUp/customerConfiguration',
  //     title: '客户设置',
  // },
  {
    path: "/pages/setUp/financialAllocation",
    title: "财务设置",
  },
  {
    path: "/pages/setUp/costtosalesrRatio",
    title: "费销比设置",
  },
  {
    path: "/pages/setUp/printTemplateSetting",
    title: "打印模板设置",
  },
  {
    path: "/pages/salesManagement/print/sales_issue/salesDeliverydetailview",
    title: "打印预览销售出库单",
  },
  {
    path: "/pages/salesManagement/print/salesReturn/return/detailsView",
    title: "打印预览销售退货单",
  },
  {
    path: "/pages/purchasingManagement/purchaseReceiptList/print/purchaseReceiptdetailView",
    title: "打印预览采购入库单",
  },
  {
    path: "/pages/purchasingManagement/purchaseOrder/printPurchaseReturndetailView",
    title: "打印预览采购退货单",
  },

  {
    path: "/pages/setUp/worktime",
    title: "考勤设置",
  },
  {
    path: "/pages/setUp/worktime/:id/People",
    title: "考勤人员",
  },
  {
    path: "/pages/setUp/operatorSettings",
    title: "业务员设置",
  },

  {
    path: "/customerDisplayManagement",
    title: "设置信息",
  },
  {
    path: "/pages/personalCenter/basicPersonalInformation/basicPersonalInformation",
    title: "个人基本信息",
  },
  {
    path: "/pages/essentialInformation/authentication/personal",
    title: "个人认证",
  },
  {
    path: "/pages/essentialInformation/authentication/changepassword",
    title: "修改密码",
  },
  {
    path: "/pages/personalCenter/enterpriseBasicInformation/informationEditing",
    title: "企业信息编辑",
  },
  {
    path: "/pages/certificates/companyCertifi",
    title: "我的证件",
  },
  {
    path: "/pages/essentialInformation/authentication/:id/homepageSetting",
    title: "首页设置",
  },
  {
    path: "/pages/personalCenter/enterprisePlatformAccount/enterprisePlatformAccount",
    title: "企业余额",
  },
  {
    path: "/pages/businessDistrict/distributor/releaseInformation",
    title: "发布商户信息",
  },
  {
    path: "/pages/businessDistrict/distributor/purchaseRecord",
    title: "经销商购买记录",
  },

  {
    path: "/pages/businessDistrict/potentialSupplier",
    title: "潜在供货商",
  },
  {
    path: "/pages/businessDistrict/myAttention",
    title: "我的关注",
  },
  {
    path: "/pages/businessDistrict/companyAdvertise/companyAdvertise",
    title: "企业推广",
  },
  {
    path: "/pages/businessDistrict/follow",
    title: "我的关注",
  },
  {
    path: "/pages/businessDistrict/enterprisefollow",
    title: "企业关注",
  },
  {
    path: "/pages/businessDistrict/enterprisePotentialCustomers",
    title: "潜在客户", //企业关注-潜在客户
  },
  {
    path: "/pages/businessDistrict/enterprisepotentialSupplier",
    title: "潜在供货商", //企业关注-潜在供货商
  },
  {
    path: "/pages/businessDistrict/enterprisemyAttention",
    title: "其他", //企业关注-其他
  },
  {
    path: "/pages/businessDistrict/mySupplier/displayApplicationIist",
    title: "陈列申请列表",
  },
  {
    path: "/pages/businessDistrict/mySupplier/reconciliation/:id/details",
    title: "资金与费用对账",
  },
  //定向预付款--余额对账
  {
    path: "/pages/businessDistrict/mySupplier/advance/:id/charge",
    title: "余额对账",
  },
  //定向预付款--余货对账
  {
    path: "/pages/businessDistrict/mySupplier/directionalAdvance/:id/payment",
    title: "余货对账",
  },
  {
    path: "/pages/businessDistrict/mySupplier/report/:id/page",
    title: "商家报货", //商圈报货页面
  },
  {
    path: "/pages/businessDistrict/mySupplier/qu/:id/order/:orderId/otation",
    title: "销售订单", //商圈
  },
  {
    path: "/pages/businessDistrict/dealerIist/home/:id/page",
    title: "公司主页",
  },
  {
    path: "/pages/businessDistrict/dealerIist/product/:id/Hotspot",
    title: "产品热点图",
  },
  {
    path: "/pages/businessDistrict/dealerIist/sales/:id/target",
    title: "销售目标",
  },
  {
    path: "/pages/businessDistrict/dealerIist/target/:id/details",
    title: "销售目标详情",
  },
  {
    path: "/pages/businessDistrict/dealerIist/desensitizationDetails",
    title: "经销商详情",
  },
  {
    path: "/pages/businessDistrict/canvasBusinessOrders",
    title: "招商招聘",
  },
  {
    path: "/pages/businessDistrict/new/:id/investment",
    title: "新增招商",
  },
  {
    path: "/pages/businessDistrict/investment/:id/details",
    title: "招商详情",
  },
  {
    path: "/pages/businessDistrict/recruitment/:id/details",
    title: "招聘详情",
  },
  {
    path: "/pages/businessDistrict/new/:id/recruitment",
    title: "新增招聘",
  },
  // {
  //     path: '/pages/businessDistrict/recruit',
  //     title: '我要招聘',
  // },
  {
    path: "/pages/businessDistrict/myNotice",
    title: "我的公告",
  },
  {
    path: "/pages/businessDistrict/announcement/:id/details",
    title: "公告详情",
  },
  {
    path: "/pages/businessDistrict/couponmanagement/coupondistribution",
    title: "优惠券批次列表",
  },
  {
    path: "/pages/businessDistrict/couponmanagement/:id/coponbatchdetail",
    title: "优惠券列表",
  },
  {
    path: "/pages/businessDistrict/couponmanagement/add/:id/conpon",
    title: "新增优惠券",
  },
  {
    path: "/pages/businessDistrict/couponmanagement/my/:id/conpon",
    title: "我的优惠券",
  },
  {
    path: "/pages/businessDistrict/couponmanagement/collectconpon",
    title: "领券中心",
  },
  {
    path: "/pages/businessDistrict/couponmanagement/coponcount",
    title: "优惠券统计",
  },
  {
    path: "/pages/businessDistrict/couponmanagement/conponusedetail",
    title: "优惠券使用详情",
  },
  {
    path: "/pages/products/rawMaterial/rawMaterialList",
    title: "原材料列表",
  },
  {
    path: "/pages/products/rawMaterial/addraw/:id/materials",
    title: "新增原材料",
  },
  // {
  //     path: '/pages/products/rawMaterial/modify/:id/materials',
  //     title: '修改原材料',
  // },
  {
    path: "/pages/products/rawMaterial/material/:id/details",
    title: "原材料详情",
  },
  {
    // path: '/pages/products/rawMaterial/set/:id/production',
    path: "/pages/products/rawMaterial/setproduction",
    title: "设置生产配比",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/rawMaterialWarehousingList",
    title: "原材料采购入列表",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/rawmaterial/:id/receipt",
    title: "原材料入库单详情",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/print/rawmaterial/:id/receipt",
    title: "打印原材料入库单详情", //打印
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/add/:id/receipt",
    title: "新增原材料入库单",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/modify/:id/purchase",
    title: "修改原材料采购入库",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/inventoryDetails",
    title: "原材料库存",
  },
  // 产品管理-产品列表
  {
    path: "/pages/products/productList",
    title: "产品列表",
  },
  {
    path: "/pages/products/coreProductManagement",
    title: "核心产品管理",
  },
  {
    path: "/pages/products/flowProductManagement",
    title: "流量产品管理",
  },
  {
    path: "/pages/products/add/:id/CoreProductManagement",
    title: "新增核心产品列表",
  },
  {
    path: "/pages/products/coreProductManagement/:id/Detail",
    title: "核心产品详情",
  },
  {
    path: "/pages/products/productQuotation/:id/list",
    title: "报价单列表",
  },
  {
    path: "/pages/products/product/:id/quotation",
    title: "报价单详情",
  },
  {
    path: "/pages/products/productInformation/:id/details",
    title: "产品列表详情",
  },
  {
    path: "/pages/products/modify/:id/product",
    title: "修改产品信息",
  },
  {
    path: "/pages/products/appealProducts",
    title: "产品经销申诉",
  },
  {
    path: "/pages/products/addAppealProducts",
    title: "新增产品经销申诉",
  },
  {
    path: "/pages/products/appealProducts/:id/details",
    title: "产品经销申诉详情",
  },
  {
    path: "/pages/products/productCert",
    title: "证件查询", //商圈,
  },
  {
    path: "/pages/products/productCertList/:id",
    title: "证件查询列表", //商圈,
  },
  {
    path: "/pages/businessDistrict/myLogistics",
    title: "我的收货",
  },
  {
    path: "/pages/products/isProductbBatchManage",
    title: "设置产品批次管理",
  },
  {
    path: "/pages/products/marketableCustomers",
    title: "产品适销渠道",
  },
  {
    path: "/pages/essentialInformation/salesDepartment",
    title: "营销部门管理",
  },
  {
    path: "/pages/essentialInformation/addPart/:id/product",
    title: "产品设置",
  },
  {
    path: "/pages/essentialInformation/partProduct/:id/details",
    title: "部门产品详情",
  },
  //产品推荐
  {
    path: "/pages/products/productRecommendation/productSalesAnalysis",
    title: "产品销售分析",
  },
  {
    path: "/pages/products/productRecommendation/salesmanSalesAnalysis",
    title: "业务员产品销售分析",
  },
  {
    path: "/pages/products/productRecommendation/customerSalesAnalysis",
    title: "客户销售分析",
  },
  {
    path: "/pages/products/productRecommendation/productmix/add/:id/RecommendProductMix",
    title: "新增推荐产品组合",
  },
  {
    path: "/pages/products/productRecommendation/productmix/recommendProductMix",
    title: "推荐产品组合列表",
  },
  {
    path: "/pages/products/productRecommendation/productmix/recommendProductMix/:id/detail",
    title: "查看推荐产品组合",
  },
  {
    path: "/pages/products/productRecommendation/customerDetail",
    title: "推荐产品客户明细",
  },
  {
    path: "/pages/products/productRecommendation/marketingProgress",
    title: "推荐产品铺市进度汇总",
  },
  {
    path: "/pages/products/productRecommendation/viewDetail",
    title: "明细查看",
  },
  // 职位管理废弃
  {
    path: "/pages/essentialInformation/personnelInformation/positionManagement",
    title: "职位管理",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/assign/:id/roles",
    title: "分配角色",
  },
  {
    path: "/pages/essentialInformation/positionManagement/salesPosition",
    title: "职位管理",
  },
  // {
  //     path: '/pages/essentialInformation/positionManagement/purchasingPosition',
  //     title: '采购职位管理',
  // },
  // {
  //     path: '/pages/essentialInformation/positionManagement/financialPosition',
  //     title: '财务职位管理',
  // },
  // {
  //     path: '/pages/essentialInformation/positionManagement/warehousePosition',
  //     title: '仓库职位管理',
  // },
  {
    path: "/pages/messageCenter/personalCenter",
    title: "个人消息",
  },
  {
    path: "/pages/messageCenter/enterpriseCenter",
    title: "企业消息",
  },
  {
    path: "/pages/products/noviceGuide",
    title: "新手指南",
  },
  {
    path: "/pages/financialManagement/purchase/advanceChargeList",
    title: "付款单列表",
  },
  {
    path: "/pages/financialManagement/purchase/printDetail/:id/detail",
    title: "付款单详情打印",
  },
  {
    path: "/pages/financialManagement/receipt/ProfileTwoTone",
    title: "收款单列表",
  },
  {
    path: "/pages/financialManagement/accountingmanagement/accountingmanagement",
    title: "收账管理",
  },
  {
    path: "/pages/financialManagement/accountingmanagement/accountmeceivable",
    title: "销售收账",
  },
  {
    path: "/pages/financialManagement/accountingmanagement/purchaseaccountmeceivable",
    title: "采购收账",
  },
  {
    path: "/pages/financialManagement/accountingmanagement/collectionRecord",
    title: "收账记录",
  },
  {
    path: "/pages/financialManagement/accountingmanagement/collectionAccountRecord",
    title: "收账账户记录",
  },
  {
    path: "/pages/personalCenter/basicPersonalInformation/edit",
    title: "个人信息编辑",
  },
  {
    path: "/pages/businessDistrict/lookingForProducts/product/:id/details",
    title: "产品详情",
  },

  {
    path: "/pages/businessDistrict/mySupplier/mySupplier",
    title: "我的供货商",
  },
  {
    path: "/pages/businessDistrict/supplierRecommend",
    title: "供货商推荐",
  },
  {
    //商圈
    path: "/pages/businessDistrict/mySupplier/display/:id/applicationIist",
    title: "费用列表",
  },
  //商圈--陈列详情
  {
    path: "/pages/businessDistrict/mySupplier/display/:id/Details",
    title: "陈列详情",
  },
  {
    path: "/pages/businessDistrict/mySupplier/reconciliation/:id/list",
    title: "对账",
  },
  {
    path: "/pages/businessDistrict/mySupplier/list/:id/account",
    title: "对账列表",
  },
  {
    path: "/pages/businessDistrict/mySupplier/list/:id/supplierNotices",
    title: "公司公告列表",
  },
  {
    path: "/pages/businessDistrict/mySupplier/quotation/:id/list",
    title: "我的订单",
  },

  {
    path: "/pages/businessDistrict/dealerIist/dealerIist",
    title: "本地经销商列表",
  },
  {
    path: "/pages/businessDistrict/dealerIist/desensitizationDetails",
    title: "经销商详情",
  },
  {
    path: "/pages/products/rawMaterial/addRawMaterials",
    title: "新增原材料",
  },
  {
    path: "/pages/products/rawMaterial/modifyRawMaterials",
    title: "修改原材料",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/rawMaterialWarehousingList",
    title: "原材料采购入列表",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/addRawMaterialReceipt",
    title: "新增原材料采购入库",
  },
  {
    path: "/pages/products/marketableCustomers",
    title: "产品适销客户",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/positionManagement",
    title: "职位管理",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/salesAreaManage",
    title: "销售区域管理",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/salesmanAreaSetting",
    title: "业务员区域设置",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/customerAreaSetting",
    title: "客户区域设置",
  },
  {
    path: "/pages/messageCenter/personalCenter",
    title: "个人消息",
  },
  {
    path: "/pages/messageCenter/enterpriseCenter",
    title: "企业消息",
  },
  // //财务-客户费用列表
  // {
  //     path: '/pages/salesManagement/customerExpenseManagement/customerFees',
  //     title: '客户费用列表',
  // },
  {
    path: "/pages/salesManagement/customerExpenseManagement/supplier_expenseList",
    title: "供货商费用列表",
  },
  {
    path: "/pages/financialManagement/purchase/advanceChargeList",
    title: "付款单列表",
  },
  {
    path: "/pages/financialManagement/receipt/printDetail/:id/detail",
    title: "付款单详情打印",
  },
  //新增付款
  {
    path: "/pages/financialManagement/purchase/:id/payment",
    title: "新增付款单",
  },
  {
    path: "/pages/financialManagement/purchase/:id/details",
    title: "付款单详情",
  },
  {
    path: "/pages/financialManagement/receipt/receipt",
    title: "收款单列表",
  },
  //新增收款
  {
    path: "/pages/financialManagement/odd_numbers _details/odd _numbers/:id/details",
    title: "单号详情",
  },
  {
    path: "/pages/financialManagement/odd_numbers_details/print/odd_numbers/:id/details",
    title: "打印付款单详情",
  },
  {
    path: "/pages/financialManagement/receipt/:id/collection",
    title: "新增收款单",
  },
  {
    path: "/pages/financialManagement/receipt/:id/details",
    title: "收款单详情",
  },
  {
    path: "/pages/financialManagement/odd_numbers_details/printreceipt/:id/details",
    title: "打印收款单详情",
  },
  {
    path: "/pages/financialManagement/rewardrecordlist",
    title: "业务奖励记录",
  },
  {
    path: "/pages/financial/management/reward/record/visit/:id/reward",
    title: "拜访奖励",
  },
  {
    path: "/pages/financial/management/reward/record/visit/:id/detail",
    title: "终端陈列人员奖励明细",
  },
  {
    path: "/pages/financialManagement/financialChallenge/financialChallengeList",
    title: "资金调拨单列表",
  },
  {
    path: "/pages/financialManagement/financialChallenge/add/:id/FinancialChallenge",
    title: "修改资金调拨单",
  },
  {
    path: "/pages/financialManagement/financialChallenge/financial/:id/detail",
    title: "资金调拨单详情",
  },
  {
    path: "/pages/financialManagement/detailed",
    title: "资金账户明细",
  },
  {
    path: "/pages/financialManagement/:id/otherexpenses",
    title: "其他支出",
  },
  {
    path: "/pages/financialManagement/:id/otherincome",
    title: "其他收入",
  },
  {
    path: "/pages/financialManagement/:id/otherExpensesDetail",
    title: "其他支出明细",
  },
  {
    path: "/pages/financialManagement/:id/otherExpensesDetails",
    title: "其他支出详情",
  },

  {
    path: "/pages/financialManagement/:id/otherIncomeDetail",
    title: "其他收入明细",
  },
  {
    path: "/pages/financialManagement/:id/otherIncomeDetails",
    title: "其他收入详情",
  },
  {
    path: "/pages/personalCenter/enterpriseBasicInformation/enterpriseBasicInformation",
    title: "企业基本信息",
  },
  {
    path: "/pages/personalCenter/enterpriseBasicInformation/open_enterprise",
    title: "开通企业",
  },
  {
    path: "/pages/essentialInformation/authentication/enterprise",
    title: "企业认证",
  },
  {
    path: "/pages/personalCenter/personalPlatformAccount/personalPlatformAccount",
    title: "个人余额",
  },
  {
    path: "/pages/personalCenter/personalPlatformAccount/recharge",
    title: "充值",
  },
  {
    path: "/pages/businessDistrict/distributor/ev/:id/aluate",
    title: "经销商评价",
  },
  {
    path: "/pages/businessDistrict/distributor/my/:id/purchaseRecord",
    title: "我的购买记录",
  },
  {
    path: "/pages/businessDistrict/distributor/purchaseDealer/:id/information",
    title: "购买经销商信息",
  },

  {
    path: "/pages/businessDistrict/potentialCustomers",
    title: "潜在客户",
  },
  {
    path: "/pages/businessDistrict/visit/:id/record",
    title: "拜访记录查看",
  },
  {
    path: "/pages/businessDistrict/visit/:id/details",
    title: "拜访详情",
  },
  {
    path: "/pages/businessDistrict/lookingForProducts/lookingForProducts",
    title: "找产品",
  },

  {
    path: "/pages/businessDistrict/dealerIist/desensitizationDetails",
    title: "经销商详情",
  },
  {
    path: "/pages/businessDistrict/batch/:id/delivery",
    title: "批量报货",
  },
  {
    path: "/pages/businessDistrict/purchase_order",
    title: "待提交采购订单列表",
  },
  {
    path: "/pages/products/rawMaterial/addRawMaterials",
    title: "新增原材料",
  },
  {
    path: "/pages/products/rawMaterial/modifyRawMaterials",
    title: "修改原材料",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/rawMaterialWarehousingList",
    title: "原材料入库单列表",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/outboundOrderList",
    title: "原材料出库单列表",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/addRawMaterialReceipt",
    title: "新增原材料采购入库",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/outboundOrder/:id/list",
    title: "新增原材料出库单",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/delivery/:id/details",
    title: "原材料出库单详情",
  },
  {
    path: "/pages/essentialInformation/rawMaterial/rawMaterialWarehousing/print/delivery/:id/details",
    title: "打印原材料出库单详情", //打印
  },
  {
    path: "/pages/products/manufacturerList",
    title: "生产厂家管理",
  },
  {
    path: "/pages/products/brandList/manufacturers",
    title: "品牌管理",
  },
  // 基本信息-产品管理-新增产品
  {
    path: "/pages/products/add/:id/products",
    title: "新增产品",
  },
  {
    path: "/pages/products/productHotspot",
    title: "产品热点图",
  },
  {
    path: "/pages/products/work/:id/Banch",
    title: "热点图",
  },

  {
    path: "/pages/products/marketableCustomers",
    title: "产品适销客户",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/positionManagement",
    title: "职位管理",
  },

  {
    path: "/pages/messageCenter/personalCenter",
    title: "个人消息",
  },
  {
    path: "/pages/messageCenter/enterpriseCenter",
    title: "企业消息",
  },

  {
    path: "/pages/financialManagement/purchase/advanceChargeList",
    title: "付款单列表",
  },
  {
    path: "/pages/financialManagement/receipt/receipt",
    title: "收款单列表",
  },
  {
    path: "/pages/financialManagement/vendoAccount/vendoAccount",
    title: "供货商账户",
  },
  {
    path: "/pages/financialManagement/vendoAccount/transaction/:id/detail",
    title: "供货商账户明细",
  },
  {
    path: "/pages/financialManagement/vendoAccount/supplier/:id/details", //
    title: "供货商对账",
  },
  //供货商账户--供货商账户--定向预付款明细--余额明细
  {
    path: "/pages/financialManagement/vendoAccount/Balan/:id/ce",
    title: "余额明细",
  },
  //供货商账户--供货商账户--定向预付款明细--余货明细
  {
    path: "/pages/financialManagement/vendoAccount/Surplus/:id/goods",
    title: "余货明细",
  },
  //(供货商里的资金与费用明细)（已去掉）
  {
    path: "/pages/financialManagement/vendoAccount/capital/:id/cost",
    title: "资金与费用明细",
  },
  {
    path: "/pages/financialManagement/customerAccount/customerAccount",
    title: "客户账户",
  },
  {
    path: "/pages/financialManagement/workerAccount/workerAccount",
    title: "员工资金账户",
  },
  {
    path: "/pages/financialManagement/workerAccount/workerAccount/:id/detail",
    title: "员工资金账户详情",
  },
  {
    path: "/pages/financialManagement/customerAccount/print/:id/KhZhList",
    title: "打印客户账户列表",
  },
  {
    path: "/pages/financialManagement/customerAccount/print/:id/GhsZhList",
    title: "打印供货商账户列表",
  },
  {
    path: "/pages/financialManagement/customerAccount/print/:id/KhZhDz",
    title: "打印客户账户对账列表",
  },
  {
    path: "/pages/financialManagement/customerAccount/print/:id/KhZhDetail",
    title: "打印客户账户详情",
  },
  {
    path: "/pages/financialManagement/customerAccount/print/:id/GhsZhDetail",
    title: "打印供货商账户详情",
  },
  {
    path: "/pages/financialManagement/customerAccount/transaction/:id/detail",
    title: "客户账户明细",
  },
  {
    path: "/pages/financialManagement/customerAccount/customer/:id/details",
    title: "客户对账",
  },
  {
    path: "/pages/financialManagement/customerAccount/reconciliationlist",
    title: "客户对账列表",
  },
  {
    path: "/pages/financialManagement/customerAccount/add/:id/Reconciliation",
    title: "新增销售对账单",
  },
  {
    path: "/pages/financialManagement/customerAccount/add/:id/PurReconciliation",
    title: "新增采购对账单",
  },
  {
    path: "/pages/financialManagement/customerAccount/reconciliation",
    title: "对账单",
  },
  {
    path: "/pages/financialManagement/customerAccount/reconciliation/:id/detail",
    title: "销售对账单详情",
  },
  {
    path: "/pages/financialManagement/customerAccount/PurReconciliation/:id/detail",
    title: "采购对账单详情",
  },
  {
    path: "/pages/financialManagement/customerAccount/reconciliation/print/:id/detail",
    title: "打印销售对账单详情",
  },
  {
    path: "/pages/financialManagement/customerAccount/reconciliation/printView",
    title: "销售对账单打印预览",
  },
  {
    path: "/pages/financialManagement/customerAccount/PurReconciliation/print/:id/detail",
    title: "打印采购对账单详情",
  },

  //客户账户--定向预付款明细--余额明细
  {
    path: "/pages/financialManagement/customerAccount/Balan/:id/ce01",
    title: "余额明细",
  },
  //客户账户--定向预付款明细--余货明细
  {
    path: "/pages/financialManagement/customerAccount/Surplus/:id/goods",
    title: "余货明细",
  },
  //客户账户--客户账户明细--资金与费用明细
  {
    path: "/pages/financialManagement/customerAccount/capital/:id/cost",
    title: "资金与费用明细",
  },
  {
    path: "/pages/products/newProductAssociation/batchAdd",
    title: "批量新增产品",
  },
  {
    path: "/pages/products/newProductAssociation/associationProductInformation/:id/details",
    title: "关联产品信息-详情",
  },
  {
    path: "/pages/products/totalProduct/totalProductList",
    title: "总产品管理",
  },
  // {
  //     path: '/pages/products/totalProduct/modifyTotal/:id/Product',
  //     title: '修改总产品',
  // },
  {
    path: "/pages/products/totalProduct/totalProduct/:id/Details",
    title: "总产品详情",
  },
  {
    path: "/pages/products/totalProduct/totalNew/:id/Products",
    title: "新增总产品",
  },
  {
    path: "/pages/products/totalProduct/allocation/:id/Inventory",
    title: "删除总产品-分配库存",
  },
  {
    path: "/pages/products/combinationProducts/combinationProductsList",
    title: "组合产品列表",
  },
  {
    path: "/pages/products/combinationProducts/addComboProduct",
    title: "新增组合产品",
  },
  {
    path: "/pages/currentCustomers/customerList",
    title: "客户列表",
  },
  {
    path: "/pages/currentCustomers/supplierAudit",
    title: "账户关联申请",
  },
  {
    path: "/pages/currentCustomers/batch/add",
    title: "批量新增客户",
  },
  {
    path: "/pages/currentCustomers/new/:id/customers",
    title: "新增客户",
  },
  {
    path: "/pages/currentCustomers/customer/:id/details",
    title: "客户详情",
  },

  {
    path: "/pages/currentCustomers/SupplierList",
    title: "供货商管理",
  },
  {
    path: "/pages/currentCustomers/batchSupplier/add",
    title: "批量新增供货商",
  },
  {
    path: "/pages/currentCustomers/new/:id/supplier",
    title: "新增供货商",
  },
  {
    path: "/pages/currentCustomers/vendor/:id/details",
    title: "供货商详情",
  },
  {
    path: "/pages/certificates/handle",
    title: "客户索证",
  },

  {
    path: "/pages/certificates/companycertifiall",
    title: "公司证件",
  },
  {
    path: "/pages/certificates/productCert",
    title: "产品证件",
  },
  {
    path: "/pages/certificates/productCertList/:id",
    title: "产品证件列表",
  },
  {
    path: "/pages/certificates/myevidence",
    title: "我的索证",
  },
  {
    path: "/pages/essentialInformation/warehouseList/warehouseList",
    title: "仓库管理",
  },
  {
    path: "/pages/essentialInformation/warehouseList/librarygoods",
    title: "库区管理",
  },
  {
    path: "/pages/essentialInformation/management/typeManagement",
    title: "类型管理",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/personnelInformationTable",
    title: "人员管理",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/roleInformation",
    title: "角色管理",
  },
  {
    path: "/pages/essentialInformation/personnelInformation/jurisdiction",
    title: "权限分配",
  },
  {
    path: "/pages/essentialInformation/cashAccount/cashAccount",
    title: "资金账户管理",
  },
  {
    path: "/pages/essentialInformation/metering/metering",
    title: "计量单位",
  },
  {
    path: "/pages/salesManagement/salesOrderList/salesOrderList",
    title: "销售订单列表",
  },
  {
    path: "/pages/salesManagement/salesReturn/salesReturn",
    title: "销售退货单列表",
  },
  {
    path: "/pages/salesManagement/salesReturn/return/:id/details",
    title: "销售退货单详情",
  },
  {
    path: "/pages/salesManagement/print/salesReturn/return/:id/details",
    title: "打印销售退货单详情",
  },
  {
    path: "/pages/salesManagement/salesReturn/:id/addSalesReturn",
    title: "新增销售退货单",
  },
  {
    path: "/pages/salesManagement/salesAdjustment/salesAdjustment",
    title: "销售调整单列表",
  },
  {
    path: "/pages/salesManagement/salesAdjustment/adjustment/:id/details",
    title: "销售调整单详情",
  },
  {
    path: "/pages/salesManagement/print/salesAdjustment/adjustment/:id/details",
    title: "打印销售调整单详情",
  },
  {
    path: "/pages/salesManagement/salesAdjustment/:id/addSalesAdjustment",
    title: "新增销售调整单",
  },
  {
    //进销存
    path: "/pages/salesManagement/salesOrderList/salesOrder/:id/Details",
    title: "销售订单详情",
  },
  {
    //进销存
    path: "/pages/salesManagement/print/salesOrderList/salesOrder/:id/Details",
    title: "打印销售订单详情", //打印
  },
  {
    path: "/pages/salesManagement/salesOrderList/:id/addSalesOrder",
    title: "新增销售订单",
  },
  {
    path: "/pages/salesManagement/takeordermanager/takeorderrecord",
    title: "业务取单管理",
  },
  {
    path: "/pages/salesManagement/takeordermanager/addtake/:id/order",
    title: "新增取单", //取单申请&确认  //也就是新增
  },
  {
    path: "/pages/salesManagement/takeordermanager/takeorder/:id/details",
    title: "取单详情", //取单详情&交账确认
  },
  {
    path: "/pages/salesManagement/visitManagement/modify/:id/line",
    title: "修改线路",
  },
  {
    path: "/pages/salesManagement/visitManagement/callline/:id/management",
    title: "拜访线路管理",
  },
  {
    path: "/pages/salesManagement/visitManagement/businessAreaManagement",
    title: "业务区域管理",
  },
  {
    path: "/pages/salesManagement/visitManagement/modifyRecord",
    title: "拜访线路审核",
  },
  {
    path: "/pages/salesManagement/visitManagement/visit/:id/record",
    title: "拜访记录",
  },
  {
    path: "/pages/salesManagement/visitManagement/visit",
    title: "拜访",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/customerDisplayManagement",
    title: "经销商陈列列表",
  },
  {
    path: "/pages/sales/management/customer/expense/management/store/display/store/display/managent",
    title: "门店陈列列表",
  },
  {
    path: "/pages/salesmanagement/customerexpensemanagement/display/:id/expenses",
    title: "陈列费用上帐",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/new/:id/display",
    title: "新增经销商陈列申请",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/new/:id/storedisplay",
    title: "新增门店陈列申请",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/approval/:id/display",
    title: "批复陈列申请",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/display/:id/inspection",
    title: "陈列检核",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/audit/:id/display",
    title: "审核陈列检核",
  },
  {
    //财务管理
    path: "/pages/salesManagement/customerExpenseManagement/display/:id/details",
    title: "陈列详情",
  },

  {
    path: "/pages/salesManagement/customerExpenseManagement/displayActivityManagement",
    title: "陈列活动管理",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/displayCapturing",
    title: "终端陈列管理",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/displayCapturingCheck",
    title: "终端陈列检查",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/:id/displayCapturingDetail",
    title: "陈列拍照详情",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/addDisplay/:id/active",
    title: "新增陈列活动", //新增、修改
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/displayActive/:id/details",
    title: "陈列活动详情", //陈列活动详情
  },
  //销售-客户费用列表
  {
    path: "/pages/salesManagement/customerExpenseManagement/customerFees",
    title: "客户费用列表",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/newexpenseapplication",
    title: "客户费用申请",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/supplier_fee_application",
    title: "供货商费用申请",
  },
  {
    path: "/pages/purchasingManagement/purchaseOrder/purchaseOrder",
    title: "采购订单列表",
  },
  {
    path: "/pages/purchasingManagement/purchaseOrder/printPurchaseOrder/:id/detail",
    title: "采购订单详情打印",
  },
  {
    path: "/pages/purchasingManagement/purchaseReturnList/purchaseReturnList",
    title: "采购退货单列表",
  },
  {
    path: "/pages/purchasingManagement/purchaseOrder/printPurchaseReturn/:id/detail",
    title: "采购退货单详情打印",
  },
  {
    path: "/pages/purchasingManagement/onekeysetting",
    title: "一键采购设置",
  },
  {
    path: "/pages/InventoryManagement/stock/examine",
    title: "库存查看",
  },
  {
    path: "/pages/InventoryManagement/stock/analysis",
    title: "库存分析",
  },
  {
    path: "/pages/InventoryManagement/stock/printavailable",
    title: "打印可用库存",
  },
  {
    path: "/pages/InventoryManagement/stock/printrealtime",
    title: "打印实时库存",
  },
  {
    path: "/pages/InventoryManagement/stock/myInventorys",
    title: "我的库区",
  },
  {
    path: "/pages/InventoryManagement/warehousingList/warehousingList",
    title: "入库单列表",
  },
  {
    path: "/pages/InventoryManagement/warehousingList/warehousing/:id/recordDetails",
    title: "入库记录详情",
  },
  {
    path: "/pages/InventoryManagement/warehousingList/print/warehousing/:id/recordDetails",
    title: "打印入库记录详情",
  },
  {
    path: "/pages/InventoryManagement/warehousingList/addReceipt",
    title: "新增入库单",
  },
  {
    path: "/pages/InventoryManagement/warehousingList/:id/receiptEdit",
    title: "入库单审核",
  },
  //采购入库单详情
  {
    path: "/pages/purchasingManagement/purchaseReceiptList/purchaseReceipt/:id/details",
    title: "采购入库单详情",
  },
  {
    path: "/pages/purchasingManagement/purchaseReceiptList/print/purchaseReceipt/:id/details",
    title: "打印采购入库单详情",
  },
  {
    path: "/pages/purchasingManagement/purchaseReceiptList/add/:id/warehousing",
    title: "新增采购入库",
  },
  {
    path: "/pages/purchasingManagement/purchaseReceiptList/addcopy/:id/warehousing",
    title: "复制采购入库",
  },
  {
    path: "/pages/purchasingManagement/purchaseReceiptList/purchaseReceiptList",
    title: "采购入库列表",
  },
  {
    path: "/pages/purchasingManagement/purchScopeSetting",
    title: "采购范围设置",
  },
  {
    path: "/pages/InventoryManagement/IssueList/IssueList",
    title: "出库单列表",
  },
  {
    path: "/pages/InventoryManagement/IssueList/delivery/:id/details",
    title: "出库列表详情",
  },
  {
    path: "/pages/InventoryManagement/IssueList/print/delivery/:id/details",
    title: "打印出库列表详情",
  },
  {
    path: "/pages/InventoryManagement/IssueList/:id/AddIssue",
    title: "新增出库单",
  },
  // {
  //     path: '/pages/InventoryManagement/IssueList/:id/AddIssue',
  //     title: '出库单审核',
  // },
  {
    path: "/pages/salesManagement/sales_issue/salesIssueList",
    title: "销售出库列表",
  },
  {
    path: "/pages/salesManagement/sales_issue/salesDelivery/:id/details",
    title: "销售出库详情",
  },
  {
    path: "/pages/salesManagement/print/sales_issue/salesDelivery/:id/details",
    title: "打印销售出库详情",
  },
  // {
  //     path: '/pages/salesManagement/print/sales_issue/sales/:id/details',
  //     title: '打印销售详情',
  // },
  {
    path: "/pages/InventoryManagement/InventoryChallenge/challengeList",
    title: "调拨单列表",
  },
  {
    path: "/pages/salesManagement/sales_issue/addsale/:id/issue",
    title: "新增销售出库单",
  },
  {
    path: "/pages/salesManagement/sales_issue/addsaleCopy/:id/issue",
    title: "新增销售出库单",
  },
  {
    path: "/pages/InventoryManagement/InventoryChallenge/new/:id/Add",
    title: "修改调拨单",
  },
  {
    path: "/pages/InventoryManagement/InventoryChallenge/transfersAdd",
    title: "新增调拨单",
  },
  {
    path: "/pages/InventoryManagement/InventoryChallenge/provocation/:id/details",
    title: "调拨单详情",
  },
  {
    path: "/pages/InventoryManagement/InventoryChallenge/printPro/:id/detail",
    title: "调拨单打印详情",
  },
  {
    path: "/pages/InventoryManagement/InventoryChallenge/print/provocation/:id/details",
    title: "打印调拨单详情",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/managementTasks",
    title: "盘点记录列表",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/:id/editInventoryRecords",
    title: "盘点记录修改",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/:id/editInventoryRecordsSpead",
    title: "盘点记录修改(极速版)",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/:id/previewPage",
    title: "盘盈盘亏预览",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/assignedTasks",
    title: "分配盘点任务",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/inventory/:id/task",
    title: "我的盘点任务",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/Inventory/:id/details",
    title: "盘点产品详情",
  },

  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/resoult/:id/detail",
    title: "盘点结果详情",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/addInventory",
    title: "新建盘点任务",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/myInventory/myInventory",
    title: "我的盘点任务",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/myInventory/want/:id/inventory",
    title: "盘点",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/myInventory/Inventory/:id/WareHouse",
    title: "清点",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/myInventory/inventory/:id/results",
    title: "我的盘点详情",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/myInventory/qingdain/:id/detail",
    title: "我的清点详情",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/print/Inventory/:id/details",
    title: "打印我的盘点详情",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/inventoryUnitManager/inventoryUnit",
    title: "盘点单元管理",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/inventoryUnitManager/addInventory/:id/unit",
    title: "新增盘点单元",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/managementTasks/add/:id/theinventory",
    title: "新增盘点",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/inventoryUnitManager/inventory/:id/detial",
    title: "盘点单元详情",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/materialManagement/managementTasks",
    title: "原材料盘点",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/materialManagement/:id/editInventoryRecords",
    title: "原材料盘点记录修改",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/materialManagement/:id/previewPage",
    title: "原材料盘盈盘亏预览",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/materialManagement/add/:id/theinventory",
    title: "新增盘点",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/inventoryMaterial/inventoryMaterialUnit",
    title: "原材料盘点单元",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/inventoryMaterial/addInventoryMaterial/:id/unit",
    title: "新增原材料盘点单元",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/myInventoryMaterial/myInventoryMaterial",
    title: "原材料盘点任务",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/myInventoryMaterial/inventoryMaterial/:id/results",
    title: "原材料盘点详情",
  },
  {
    path: "/pages/InventoryManagement/InventoryCount/myInventoryMaterial/want/:id/inventoryMaterial",
    title: "原材料盘点任务详情",
  },
  {
    path: "/pages/salesManagement/promotionManagement/promotionList",
    title: "促销活动列表",
  },
  {
    path: "/pages/salesManagement/salesTarget/customerObjectives/customerObjectives",
    title: "客户销售目标列表",
  },
  {
    path: "/pages/salesManagement/salesTarget/print/:id/cSalesTarget",
    title: "打印客户销售目标列表",
  },
  {
    path: "/pages/stareport/customerTargetStatistics",
    title: "客户销售目标统计",
  },
  //客户--新增、修改
  {
    path: "/pages/salesManagement/salesTarget/customerObjectives/newSalesTarget",
    title: "新增客户销售目标",
  },
  {
    path: "/pages/salesManagement/salesTarget/businessObjectives/businessObjectives",
    title: "业务销售目标列表",
  },
  {
    path: "/pages/salesManagement/salesTarget/print/:id/wSalesTarget",
    title: "打印业务销售目标列表",
  },
  //业务
  {
    path: "/pages/salesManagement/salesTarget/businessObjectives/newSalesTarget",
    title: "新增业务销售目标",
  },
  {
    path: "/pages/salesManagement/directionalAdvanceCollection/list",
    title: "定向预收款列表",
  },
  {
    path: "/pages/salesManagement/directionalAdvanceCollection/:id/addlist",
    title: "新增定向预收款",
  },
  {
    path: "/pages/salesManagement/directionalAdvanceCollection/:id/details",
    title: "定向预收款详情",
  },
  {
    path: "/pages/salesManagement/directionalAdvanceCollection/print/:id/details",
    title: "打印定向预收款详情",
  },
  {
    path: "/pages/salesManagement/personnelIncentive/rewardSchemeSettings/incentiveSchemesList",
    title: "业务激励方案管理",
  },
  {
    path: "/pages/salesManagement/personnelIncentive/rewardRecordlist",
    title: "我的奖励",
  },
  {
    path: "/pages/potal/register",
    title: "注册",
  },
  {
    path: "/pages/potal/switch/:id/positioning",
    title: "定位",
  },
  {
    path: "/pages/businessDistrict/lookingForProducts/factory/:id/details",
    title: "公司主页",
  },

  //采购管理
  {
    path: "/pages/purchasingManagement/purchaseOrder/addPurchaseOrder2",
    title: "一键采购",
  },
  {
    path: "/pages/purchasingManagement/purchaseOrder/addPurchase/:id/order",
    title: "新增采购订单",
  },
  {
    path: "/pages/purchasingManagement/purchaseOrder/bulkpurchase",
    title: "批量采购",
  },
  {
    path: "/pages/purchasingManagement/purchaseOrder/purchaseOrder/:id/details",
    title: "采购订单详情",
  },
  {
    path: "/pages/purchasingManagement/purchaseOrder/print/purchaseOrder/:id/details",
    title: "打印采购订单详情",
  },
  {
    path: "/pages/purchasingManagement/purchaseOrder/receiving",
    title: "采购订单收货",
  },
  {
    path: "/pages/purchasingManagement/purchaseReturnList/modifyPurchaseReturn",
    title: "修改采购退货单",
  },
  {
    path: "/pages/purchasingManagement/purchaseReturnList/purchaseReturnOrder/:id/details",
    title: "采购退货单详情",
  },
  {
    path: "/pages/purchasingManagement/purchaseReturnList/print/purchaseReturnOrder/:id/details",
    title: "打印采购退货单详情",
  },
  {
    path: "/pages/purchasingManagement/purchaseReturnList/returnShipmentApproval",
    title: "采购退货单审核",
  },
  {
    path: "/pages/purchasingManagement/purchaseReturnList/addPurchase/:id/return",
    title: "新增采购退货单",
  },
  // {
  //     path: '/pages/purchasingManagement/quotation/quotationlist',
  //     title: '供货商报价单列表'
  // },
  // {
  //     path: '/pages/purchasingManagement/quotation/quotation/:id/detail',
  //     title: '供货商报价单详情'
  // },
  // 商圈
  {
    path: "/pages/businessDistrict/:dealer/id/:id/newDisplayApplication",
    title: "经销商陈列申请",
  },
  {
    path: "/pages/attendanceManagement/attendanceList",
    title: "考勤信息",
  },
  {
    path: "/pages/attendanceManagement/trajectory",
    title: "上班轨迹",
  },
  {
    path: "/pages/businessDistrict/display/:id/inspection",
    title: "陈列检核列表",
  },
  // 商圈-销售订单详情
  {
    path: "/pages/businessDistrict/sales/:id/orderDetails",
    title: "销售订单详情",
  },
  {
    path: "/pages/products/new/:id/quotation",
    title: "新增报价单",
  },
  {
    path: "/pages/businessDistrict/customerData",
    title: "客户数据查看",
  },
  {
    path: "/pages/businessDistrict/view/:id/details",
    title: "查看详情",
  },
  {
    path: "/pages/businessDistrict/mySupplier/display/:id/details",
    title: "客户陈列详情",
  },
  {
    path: "/pages/financialmanagement/directionaladvance/directionaladvancelist",
    title: "定向预付款列表",
  },
  {
    path: "/pages/financialmanagement/directionaladvance/adddirect/:id/advance",
    title: "新增定向预付款",
  },
  {
    path: "/pages/financialmanagement/directionaladvance/detai/:id/ls",
    title: "定向预付款详情",
  },
  {
    path: "/pages/financialmanagement/directionaladvance/print/detai/:id/ls",
    title: "打印定向预付款详情",
  },
  {
    path: "/pages/inventory/management/receiving/record",
    title: "验收记录",
  },
  {
    path: "/pages/inventory/management/receiving/:id/details",
    title: "验收详情",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/confirm/:id/storedisplay",
    title: "批复门店陈列",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/audit/:id/:displayDate/storedisplay",
    title: "审核门店陈列检核",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/settle/:id/:displayDate/storedisplay",
    title: "结算门店陈列检核",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/detail/:id/:displayDate/storedisplay",
    title: "查看门店陈列明细",
  },
  {
    path: "/pages/businessDistrict/:dealer/displaylist",
    title: "经销商陈列列表",
  },
  {
    path: "/pages/businessDistrict/store/:id/:applyId/application",
    title: "门店陈列申请",
  },
  {
    path: "/pages/businessDistrict/storeDisplay/:id/list",
    title: "门店陈列列表",
  },
  {
    path: "/pages/salesManagement/customerExpenseManagement/detail/:supplierId/:id/:displayDate/circle/storedisplay",
    title: "商圈门店陈列",
  },
  {
    path: "/pages/stareport/goodsPromotion",
    title: "促销商品销售统计表",
  },
  {
    path: "/pages/stareport/chanceStatistical",
    title: "陈列费用审核通过率统计",
  },
  {
    path: "/pages/stareport/progressStatistical",
    title: "门店检核进度统计",
  },
  {
    path: "/pages/stareport/productSalesStatistics",
    title: "产品销售汇总表",
  },
  {
    path: "/pages/stareport/giftProductSalesStatistics",
    title: "赠品销售统计",
  },
  {
    path: "/pages/stareport/coreProductSalesStatistics",
    title: "核心产品销售统计",
  },
  {
    path: "/pages/stareport/flowProductSalesStatistics",
    title: "流量产品销售统计",
  },
  {
    path: "/pages/stareport/salesmanSalesStatistics",
    title: "业务员销售统计",
  },
  {
    path: "/pages/stareport/sales/:workerId/manSalesSum",
    title: "业务员销售统计明细",
  },
  {
    path: "/pages/stareport/salesStatistics",
    title: "客户销售统计",
  },
  {
    path: "/pages/stareport/:id/customersalesDetail",
    title: "客户销售统计明细",
  },
  {
    path: "/pages/stareport/visitStatistics",
    title: "业务员拜访统计",
  },
  {
    path: "/pages/stareport/salesvisitStatistics",
    title: "客户拜访统计表",
  },
  {
    path: "/pages/stareport/salesmanDaily",
    title: "业务员日报表",
  },
  {
    path: "/pages/stareport/buyerGrowth",
    title: "采购员采购类别同比 环比增长情况",
  },
  {
    path: "/pages/stareport/salesStatistics2",
    title: "客户销售统计",
  },
  {
    path: "/pages/stareport/salesmanTargetStatistics",
    title: "业务员销售目标统计",
  },
  {
    path: "/pages/stareport/customerTargetStatistics",
    title: "客户销售目标统计",
  },
  {
    path: "/pages/stareport/outStockAccount",
    title: "商品出库明细表",
  },
  {
    path: "/pages/stareport/inStockAccount",
    title: "商品入库明细表",
  },
  {
    path: "/pages/stareport/productMoves",
    title: "产品流向明细表",
  },
  {
    path: "/pages/stareport/customerArrears",
    title: "客户欠款总额明细",
  },
  {
    path: "/pages/stareport/salesDetails",
    title: "销售明细",
  },
  {
    path: "/pages/stareport/salesIssueDetails",
    title: "销售出库明细",
  },
  {
    path: "/pages/stareport/salesReturnDetails",
    title: "销售退货明细",
  },
  {
    path: "/pages/stareport/purchasesDetails",
    title: "采购明细",
  },
  {
    path: "/pages/stareport/adjustmentDocDetails",
    title: "调整单明细",
  },
  {
    path: "/pages/stareport/businessEfficiency",
    title: "业务员销售分析",
  },
  {
    path: "/pages/stareport/businessIncome",
    title: "业务收入分析",
  },
  {
    path: "/pages/stareport/businessAnalysis",
    title: "经营分析页面",
  },
  {
    path: "/pages/stareport/transferDetails",
    title: "调拨单明细",
  },
  {
    path: "/pages/stareport/inventoryHistoryDetails",
    title: "库存历史明细",
  },
  {
    path: "/pages/stareport/incomeStatement",
    title: "利润表",
  },
  {
    path: "/pages/stareport/withoutVisitingStore",
    title: "20天未拜访门店明细",
  },
  {
    path: "/pages/stareport/profitReport",
    title: "商品利润表",
  },
  {
    path: "/pages/stareport/managementDaily",
    title: "经营日报",
  },
  {
    path: "/pages/attendanceManagement/attendanceStatistics",
    title: "考勤统计",
  },
  {
    path: "/pages/attendanceManagement/attendance/:id/Detail",
    title: "个人考勤详情",
  },
  {
    path: "/pages/stareport/customerSale",
    title: "客户开单统计",
  },
  {
    path: "/pages/stareport/customerDebt",
    title: "客户欠款统计表",
  },
  {
    path: "/pages/stareport/customerDebt/:id/Detail",
    title: "欠款单据详情",
  },
  {
    path: "/pages/stareport/supplierDebt",
    title: "供货商欠款统计表",
  },
  {
    path: "/pages/stareport/supplierDebt/:id/Detail",
    title: "欠款单据详情",
  },
  {
    path: "/pages/stareport/salesTrendAnalysis",
    title: "销售趋势分析",
  },
];
