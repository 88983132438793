import request from "../utils/awaitrequest";

const printConfigs = {}
const previewPrintConfigs = {}//预览对象

export function getPrintConfigUtil(businessType) {
    if (businessType == null) {
        return {};
    }
    let key = businessType + '';
    let value = printConfigs[key];
    if (value == null) {
        return {
            printBusinessItem: {}
        };
    }
    return value;
}

export async function initPrintSaleConfig() {
    await request('/print/business/one', { body: 3 }).then(res => {
        if (res.code == 0) {
            if (res.item.companyId == null) {
                res.item.companyId = 1;
            }
            console.log('初始化打印配置', res.item)
            printConfigs[3 + ''] = res.item;
        }
    });
}
export function setPrintConfigUtil(businessType, config) {
    printConfigs[businessType + ''] = config;
}

export function getPreviewPrintConfigUtil(businessType) {
    if (businessType == null) {
        return {};
    }
    let key = businessType + '';
    let value = previewPrintConfigs[key];
    if (value == null) {
        return {
            printBusinessItem: {}
        };
    }
    return value;
}

export function setPreViewPrintConfigUtil(businessType, config) {
    previewPrintConfigs[businessType + ''] = config;
}



