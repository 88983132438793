import { getAntiRepeat, getClientHeader, saveAntiRepeat } from './clientToken'
import { api, numberRequestedVersion, trimSpace } from './util';
let proxy = api;

export const TypeEnum = 'no';


//不用sessionStorage存antiRepeat是因为存储时间长
function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        let headers = response.headers;
        if (!!headers) {
            let antObj = getAntiRepeat();
            if (headers.get('antiRepeat') && headers.get('antiRepeat') != antObj.antiRepeatToken && headers.get('antiRepeat') != antObj.lastAntiRepeatToken) {
                // console.log('...checkStatus..headers.....', headers.get('antiRepeat'), '........headers>>>>>>>>')
                saveAntiRepeat({ antiRepeatToken: headers.get('antiRepeat') })
            }
        }
        return response.json();
    } else {
        return new Promise((resolve) => resolve({ msg: '请求出错，网络状态码：' + response.status, code: 10, }));

    }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
function request(url, options) {
    // debugger
    const defaultOptions = {
        credentials: 'include',
    };
    let newOptions = { ...defaultOptions, ...(options || {}), method: (options || {}).method || 'POST' };
    if (newOptions.method === 'POST' || newOptions.method === 'PUT') {
        let tt = getClientHeader();
        newOptions.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            ...newOptions.headers,
            "laozhuyi-type": tt.type,
            "laozhuyi-token": tt.token,
            "laozhuyi-sign": tt.sign,
            "lzy_client_version": numberRequestedVersion,
        };
        // console.log('...request..antiRepeat.....', antiRepeatToken, '........utl>>>>>>>>', url)
        let antObj = getAntiRepeat();
        if (!!antObj.antiRepeatToken) {
            newOptions.headers.antiRepeat = antObj.antiRepeatToken;
            saveAntiRepeat({ lastAntiRepeatToken: antObj.antiRepeatToken })
        }

        //body里面的参数去空格
        if (newOptions.body) {
            if (newOptions.body['condition'] && !Array.isArray(newOptions.body['condition'])) {
                newOptions.body['condition'] = trimSpace(newOptions.body['condition'])
            }
            if (newOptions.body['criteria'] && !Array.isArray(newOptions.body['criteria'])) {
                newOptions.body['criteria'] = trimSpace(newOptions.body['criteria'])
            }
        }
        newOptions.body = JSON.stringify(newOptions.body)
    }
    const path = proxy + url;
    return fetch(path, newOptions)
        .then(checkStatus)
        .then(json => {
            // window.location.href = '/work/potal/systemUpgrade';
            // throw new Error('需要登录');
            if (json.code == 2) {
                window.location.href = '/work/potal/SignIn';
                // throw new Error('需要登录');
            }
            // console.log('..json......', JSON.stringify(json))
            return new Promise((resolve) => resolve(json));
        })
        .catch((error) => {
            console.log("ajax is error!", error);
            return { msg: '接口：' + url + '调用出错,错误原因：' + error, code: 10, };
        });
}
export default request