import request from "../utils/awaitrequest";

let saleDepartListConfigs = [];
const saleDepartList = '/common/sale/partment/list'
export function getSalesDeparts() {
    if (!!!saleDepartListConfigs || saleDepartListConfigs.length <= 0) {
        return [];
    }
    return [...saleDepartListConfigs];
}

export async function initSaleDepartConfigs() {
    await request(saleDepartList, { body: {} }).then((res) => {
        if (res.code == 0) {
            saleDepartListConfigs = (res.item || []);
        }
    })
}